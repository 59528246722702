// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // loginServiceUrl: 'https://gulapuckar-api-daniel.nytthem.sparbankensyd.se',
  loanServiceUrl: 'https://gulapuckar-www-daniel.nytthem.sparbankensyd.se/api',
  commonServiceUrl: 'https://ll-test.internal.sparbankensyd.se/service',
  userDoc: 'https://api-user-doc-daniel.lana.sparbankensyd.se/doc',
  showDebug: true,
  domain: 'll-test.internal.sparbankensyd.se'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
