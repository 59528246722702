import {HttpClient} from '@angular/common/http'
import {Injectable, signal} from '@angular/core'
import {
  IApplication,
  IApplicationAdmin,
  IApplicationSummary,
  IApplicationUpdate,
  IApplicationWithChanges
} from '@sparbanken-syd/loan-backend'
import {BehaviorSubject, Observable} from 'rxjs'
import {map} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {IUser} from '../application/types'

@Injectable({
  providedIn: 'root'
})
export class LoanService {

  public users$: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([])
  /**
   * Interested parties may subscribe to this to have a short list
   * of loan promises to look at.
   */
  public loanPromiseList: BehaviorSubject<IApplicationSummary[]> = new BehaviorSubject<IApplicationSummary[]>([])

  /**
   * Event emitter that emits an updated application details
   */
  public applicationUpdated$: BehaviorSubject<IApplicationWithChanges | IApplication | null> =
    new BehaviorSubject<IApplicationWithChanges | IApplication | null>(null)
  /**
   * Updated application details (loan promise data)
   */
  public application$: BehaviorSubject<IApplicationWithChanges | null> =
    new BehaviorSubject<IApplicationWithChanges | null>(null)

  public isMockUcActive$ = signal<boolean>(false)

  /**
   * Keep a local representation of all loan promises
   */
  private loanPromises: IApplicationSummary[] = []

  /**
   * When was the last time we fetched data (loan promise list)
   */
  private lastFetchTime: number = 0

  constructor(
    private httpClient: HttpClient
  ) {
  }

  /**
   * Fetch existing loan promise
   * @param id - The ID of the promise
   */
  public fetchComplete(id: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/promise/${id}/complete`
    return this.httpClient.get<any>(url)
  }

  public updateApplication(id: string, data: IApplicationUpdate): Observable<IApplication | IApplicationWithChanges> {
    const url = `${environment.loanServiceUrl}/promise/${id}`
    return this.httpClient.put<any>(url, data)
  }

  public runUc(id: string): Observable<IApplication> {
    const url = `${environment.loanServiceUrl}/promise/${id}/uc`
    return this.httpClient.put<any>(url, {mock: this.isMockUcActive$()})
  }

  public issueLoan(id: string, notes?: string): Observable<IApplication> {
    const url = `${environment.loanServiceUrl}/promise/${id}/issue`
    return this.httpClient.put<any>(url, {notes: notes})
  }

  public delete(id: string): Observable<void> {
    const url = `${environment.loanServiceUrl}/admin/${id}`
    return this.httpClient.delete<void>(url).pipe(
      map(() => {
        this.loanPromises = this.loanPromises.filter(lp => lp['loan-promise-id'] !== id)
        this.loanPromiseList.next(this.loanPromises)
        return
      })
    )
  }

  public list(): Observable<Array<IApplicationSummary>> {
    const url = `${environment.loanServiceUrl}/promise/list?from=${this.lastFetchTime}`
    return this.httpClient.get<IApplicationSummary[]>(url)
      .pipe(
        map(list => {
          this.loanPromises = list
          this.loanPromiseList.next(this.loanPromises)
          this.lastFetchTime = new Date().getTime()
          this.getUsers()
          return this.loanPromises
        })
      )
  }

  public updateAssignee(id: string, data: IApplicationAdmin): Observable<IApplicationSummary> {
    const url = `${environment.loanServiceUrl}/admin/${id}`
    return this.httpClient.put<IApplicationSummary>(url, data)
  }

  public fetchLoanDocument(id: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/documents/${id}`
    return this.httpClient.get<any>(url)
  }

  /**
   * Get the UC as HTML
   */
  public getHtml(personNummer: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/reports/${personNummer}`
    return this.httpClient.get<Array<any>>(url)
  }

  /**
   * Fetch a list of users, send them on the sub if not already
   * fetched.
   */
  private getUsers(): void {
    const url = `${environment.commonServiceUrl}/users`
    if (this.users$.getValue().length === 0) {
      this.httpClient.get<IUser[]>(url).subscribe((users: IUser[]) => {
        const filtered = users.filter(u => {
          return u.roles.indexOf('admin') !== -1 && u.roles.indexOf('developer') === -1
        })
        this.users$.next(filtered)
      })
    }
  }
}
