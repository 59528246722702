import {Component, Injectable, Input, OnInit, signal, ViewEncapsulation, WritableSignal} from '@angular/core'
import {MatSnackBar} from '@angular/material/snack-bar'
import {BehaviorSubject, filter} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading$: WritableSignal<boolean> = signal(false)
  public loadingMessage$: WritableSignal<string> = signal('')
  public snackbarData$ = new BehaviorSubject<string | null>(null)

  public startLoading(message: string) {
    this.isLoading$.set(true)
    this.loadingMessage$.set(message)
  }

  public stopLoading(message?: string | any) {
    this.isLoading$.set(false)
    this.loadingMessage$.set('')
    this.snackbarData$.next(message ?
      (typeof message === 'string' ? message : (message.errorMessage ?? message.error?.errorMessage))
      : undefined)
  }
}

@Component({
  selector: 'spb-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit {
  @Input() top: number = 0

  constructor(
    protected loaderService: LoaderService,
    private snackbar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.loaderService.snackbarData$
      .pipe(filter(Boolean))
      .subscribe(message => {
        this.snackbar.open(message, undefined, {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 2500
        })
      })
  }
}
